import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import H1 from 'components/deprecated/legacyElements/H1';
import styles from './style.module.scss';

export default class Error404Page extends PureComponent {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.errorHeader}>
          <span>Error 404</span>
          <H1>Page Not Found</H1>
        </div>
        <div className="inset">
          <p>
            We can&rsquo;t seem to find the page you are looking for.
            <br />
            Try these links instead:
          </p>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/office-space/ny/new-york">
                Search for Office Space
              </Link>
            </li>
            <li>
              <a href="/about">About SquareFoot</a>
            </li>
            <li>
              <a href="/contact-us">Contact</a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
